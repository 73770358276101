.nav-content{
    display: flex;
flex-direction: column;
   margin: 0;
   padding: 0;
   padding-top: 0;
   justify-content: flex-end;
background-color: rgba(253, 253, 253, 0);
.top {
  margin: 0;
  padding: 0;
width: 15vw;
	position:relative;
	height: 2px;
	background: linear-gradient(141deg, rgb(255, 230, 0) 0%, rgb(153, 100, 51) 40%, rgb(255, 94, 20) 90%);
}

  @media screen and (max-width: 480px){ 
    
    display: flex;
    gap: 1rem;
    margin-left: 0;
    
}

}




/*.nav-content{
  display: flex;
margin-left: 15rem;
 
 justify-content: flex-end;
background-color: rgba(253, 253, 253, 0);
gap: 15rem;
@media screen and (max-width: 480px){ 
  display: flex;
  gap: 1rem;
  margin-left: 0;
  
}

}*/

