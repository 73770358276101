.question-box {
    position: fixed;
    bottom: 50%;
    right: 20%;
    z-index: 100px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    margin: 20px auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    @media only screen and (max-width: 600px) {
       display: none;
    }
  }
  
  .question-box h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .question-box input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .question-box button {
    background: #3897f0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .question-box button:hover {
    background: #3184d6;
  }
  
  .response h4 {
    color: #3897f0;
    margin-bottom: 10px;
  }
  
  .response p {
    font-size: 16px;
    color: #555;
  }