.MuiBox-root {
  background: #343242bb;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.background__sorteo{
  background: #10111a;
  height: 100vh;
}


//Header
#form__header--sorteo{
  text-align: center;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;

  .barra-donaciones{
    background-color: #56586996;
    display: flex;
    height: 3rem;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    
  }

  .barra-donaciones--boton{
    text-decoration: none;
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    width: 6rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 400;
    border-radius: 10px;
    border: rgb(60, 179, 139) 1px solid ;
    color: rgb(5, 31, 32);
    height: 2rem;
    margin: 5px;
    background-color: rgb(60, 179, 139)
  }
  h1{
    
    color: rgb(60, 179, 139);
    
    font-family: Arial, Helvetica, sans-serif;
  }
  p{
    color: rgb(245, 250, 247);
    font-family: Arial, Helvetica, sans-serif;
  }
}



.custom-list-item{
  color: aliceblue;
  text-decoration: none;
 
}

//CountDown
$bgColor: #32386D;

$numOfColBlocks: 3; // must be higher than 2
$deg: 360deg / $numOfColBlocks;

$red: #FD3359;
$yellow: #F4D302;
$blue: #21BDFF;

$blocksColors: (
  1: $red,
  2: $yellow,
  3: $blue
);


$size: 140px;
$sizeBig: 500px;
$pad: 10px;
$numSize: 100px;
$totalAT: 4s;
$contBr: 20px;

$num1Len: 72.1554946899414;
$num2Len: 136.02162170410156;
$num3Len: 144.4256591796875;
$numJoin1-2: 82.63925170898438;
$numJoin2-3: 42.81303787231445;
$numJoin3-0: 40;

$totalLen: $num1Len + $num2Len + $num3Len + $numJoin1-2 + $numJoin2-3 + $numJoin3-0;

//body {
//  background: $bgColor;
//  font-family: Helvetica, Arial, sans-serif;
//}

.demo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: $sizeBig;
  height: $size;
  margin-top: $size / -2;
  padding: $pad;
  border-radius: 20px;
  transform: translateX(-50%);

  &__colored-blocks {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    width: $sizeBig;
    height: 100%;
    margin-left: $sizeBig/-2;
    padding: $pad;
    border-radius: $contBr;
    perspective: 1000px;
    animation: demoAnim $totalAT ease-in-out infinite, contAnim $totalAT infinite;

    &-rotater {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      animation: rotation 1.3s linear infinite;
    }

    &-inner {
      overflow: hidden;
      position: relative;
      height: 100%;
      background: $bgColor;
      border-radius: inherit;
    }
  }

  &__colored-block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    transform-origin: 0 0;

    @for $i from 1 through $numOfColBlocks {
      &:nth-child(#{$i}) {
        $rt: $deg * ($i - 1);
        $skew: 90deg - $deg;
        transform: rotate($rt) skewX($skew);
        background-color: map-get($blocksColors, $i);
      }
    }
  }

  &__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__numbers {
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    width: $numSize;
    height: $numSize;
    margin-left: $numSize/-2;
    margin-top: $numSize/-2;

    &-path {
      fill: none;
      stroke-width: 10px;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 0, $totalLen;
      stroke-dashoffset: 0;
      animation: numAnim $totalAT ease-in-out infinite;
      opacity: 0;
    }
  }

  &__text {
    position: absolute;
    left: 50%;
    top: 0;
    width: $sizeBig;
    height: 100%;
    margin-left: $sizeBig/-2;
    text-align: center;
    line-height: $size;
    font-size: 100px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 15px;
    transform: translateX(10px);
    animation: hideText $totalAT infinite;
  }
}

@keyframes contAnim {
  15%, 100% {
    margin-left: $sizeBig/-2;
    width: $sizeBig;
  }
  25%, 90% {
    margin-left: $size/-2;
    width: $size;
  }
}

@keyframes numAnim {
  15% {
    stroke-dasharray: 0, $totalLen;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  25%, 41% {
    opacity: 1;
    stroke-dasharray: $num3Len, $totalLen;
    stroke-dashoffset: -$numJoin3-0;
  }
  53%, 66% {
    stroke-dasharray: $num2Len, $totalLen;
    stroke-dashoffset: -$num3Len - $numJoin2-3 -$numJoin3-0;
  }
  76% {
    stroke-dasharray: $num1Len + $numJoin1-2/2, $totalLen;
    stroke-dashoffset: -$num3Len - $numJoin2-3 - $num2Len - $numJoin1-2 -$numJoin3-0;
  }
  88%, 100% {
    stroke-dasharray: $num1Len, $totalLen;
    stroke-dashoffset: -$num3Len - $numJoin2-3 - $num2Len - $numJoin1-2 -$numJoin3-0;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }

}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes demoAnim {
  15% {
    border-radius: $contBr;
    transform: rotate(0);
  }
  30%, 43% {
    border-radius: 50%;
    transform: rotate(360deg);
  }

  52%, 65% {
    border-radius: 0;
    transform: rotate(720deg);
  }

  78%, 90% {
    border-radius: 50%;
    transform: rotate(1080deg);
  }
  
  100% {
    border-radius: $contBr;
    transform: rotate(1440deg);
  }
}

@keyframes hideText {
  15%, 100% {
    opacity: 1;
  }
  20%, 96% {
    opacity: 0;
  }
}
