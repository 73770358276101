body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
// Declarar variables
$verde: #80cc2a;
$bordo: #db0c9b;
$durazno: #ffbf6c;
$amarillo: #f3ce0d;
$violeta: #8c52ff;
$rosa:#fc3dfc;
$naranja: rgb(255, 145, 0);
$azul: rgb(0, 162, 255);
