.oferta-section {
    padding: 50px;
    background-color: #e96c18;
    max-width: 100%;
  }
  
  .oferta-content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    
  }
  
  
  .oferta-content h3 {
    color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    font-size: 1.7rem;
    font-weight: 700;
 
  }
  


  .oferta-content__row{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 480px) { /* Pantallas muy pequeñas */
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-width: 85%;
      gap: 0.5em;
      margin-left: 10px;
    }
  }

  .oferta-content__box{
    border-radius: 20PX;

  }

  #ofb1 {
    background-color: #afafafb4; /* Segundo hijo - Azul */
    border-radius: 20PX;
    width: 10rem;
    height: 12rem;
    @media (max-width: 480px) { /* Pantallas muy pequeñas */

      width: 8rem;
      height: 12rem;
    }
  }

  #ofb2 {
    background-color: #afafafb4 ;/* Segundo hijo - Azul */
    border-radius: 20PX;
    width: 10rem;
    height: 12rem;
    @media (max-width: 480px) { /* Pantallas muy pequeñas */

      width: 8rem;
      height: 12rem;
    }
  }

  #ofb3 {
    background-color: #afafafb4; /* Segundo hijo - Azul */
    border-radius: 20PX;
    width: 10rem;
    height: 12rem;
    @media (max-width: 480px) { /* Pantallas muy pequeñas */

      width: 8rem;
      height: 12rem;
    }
  }

  .oferta-content__col{
    display: flex;
    padding: 5px;
    padding-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .oferta-content__col p {
    font-weight: 600;
    font-size: 1rem;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    line-height: 1.6;
  }
  