.stars {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    width: 4px;
    height: 4px;
    background: rgb(194, 202, 74);
    border-radius: 50%;
    box-shadow: 0 0 5px rgb(180, 168, 53);
    animation: star-fall 3s infinite;
  }
  
  @keyframes star-fall {
    0% {
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateY(10vh) translateX(10vw);
      opacity: 0;
    }
  }