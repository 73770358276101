#form__header{
  text-align: center;
  margin: 0;
  padding: 0;
}

.form-background{
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: #242121;

display: flex;
justify-content: center;
flex-direction: column;
}

.book-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    font-size: 1rem;
    color: #333;
    flex: 1;
  }

  .form-input__titulo {
    resize:vertical;
    flex: 2;
    height: 70px;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #d1d9e6;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .form-input {
    flex: 2;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #d1d9e6;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .form-input:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  .textarea {
    resize: none;
    height: 300px;
    scrollbar-width:auto ;
  }
  
  .voice-button {
    font-size: 1.2rem;
    background: #0782d4;
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .voice-button:hover {
    background: #357ab7;
    
  }
  
  
  .submit-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #4a90e2;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #357ab7;
  }

