header{
    display: flex;
flex-direction: column;
   justify-content: center;
margin-top: -1%;
  
  @media screen and (max-width: 480px){ 
    display: flex;

    margin-left: 0;
    
}

.home__intro{
  display: flex;
justify-content: center;
  flex-direction: column;
 
}
.home__intro--epigrafre{
  display: flex;
justify-content: center;
  flex-direction: row;
  gap: 0.2rem;

  :nth-child(1) {
    color: $naranja;
  }
  :nth-child(2) {
    color: $naranja ;
  }
  :nth-child(3) {
    color: $naranja;
  }

}

.home__intro--epigrafre2{
  display: flex;
justify-content: center;
  flex-direction: row;
  gap: 0.2rem;

  :nth-child(1) {
    color: $verde;
  }
  :nth-child(2) {
    color: $verde;
  }

}

h1{
  color: #ffffff;
  font-weight: 900;
  z-index: 200;
  text-shadow: 1px 1px 2px black;
  font-family: monospace;
}
p{
  color: rgb(223, 208, 75);
  text-shadow: 1px 1px 2px black;
  z-index: 200;
  font-weight: 200;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  
}

}


