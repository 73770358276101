.about-section {
    padding: 50px;
    background-color: #f3f3f3;
    max-width: 100%;
    
  }
  
  .about-content {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-content h2 {
    font-size: 1rem;
    font-weight: 700;
    color: #a912bd;
  }
  
  .about-content h3 {
    font-size: 1.7rem;
    font-weight: 700;
    color: #070707;
  }
  
  .about-content p {
    font-weight: 400;
    font-size: 1.2rem;
    color: #2b2828;
    line-height: 1.6;
  }
  