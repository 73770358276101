.chat-assistant {
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
  }
  
  .suggestions {
    margin-bottom: 10px;
  }
  
  .suggestions p {
    font-weight: bold;
  }
  
  .suggestions button {
    margin: 5px 0;
    padding: 8px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .suggestions button:hover {
    background-color: #c0c0c0;
  }
  
  .messages {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .user-message, .assistant-message {
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  .user-message {
    background-color: #d1e7dd;
    text-align: right;
  }
  
  .assistant-message {
    background-color: #f8d7da;
    text-align: left;
  }
  
  