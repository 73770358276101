.home__apps{
    display: flex;
   
    height: 320px;
    justify-content: center;
    flex-direction: row;
}

.home__apps--ul {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    gap: 1.5rem;
}

.home__apps--ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 150px;
    border-radius: 15px;
    box-sizing: border-box;
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(6px);
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
    font-weight: bolder;
    color: black;
  
}

.home__apps--ul li:hover {
    border: 1px solid black;
    transform: scale(1.05);
}

.home__apps--ul li:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }

#home__apps--li{
    
        &__1{
            background-color: #0db2f3;
            z-index: 200;
        }
        &__2{
            background-color: #077d41;
           
           
            &--img{
                margin-left: 45px;
                z-index: 100;
            }
        }
        &__3{
            background-color: #db0c9b;
            z-index: 200;
        }
        &__4{
            background-color: #ffbf6c;
            z-index: 300;
        }
        &__5{
            background-color: #f3ce0d;
            z-index: 200;
        }
        &__6{
            background-color: #8c52ff;
        }
}