.App{
    width: 100%;
   text-align: center;
    background-color: #111111;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
        justify-content: space-between;
        flex-direction: column;
}

.img-cat{
    display: flex;
    position: absolute;
        z-index: 100;
        justify-content: center;
        align-items: center ;
        top: 4.5%;
        right: 33%;
    img{
        height: 10rem;   
        width: 10rem;
        z-index: 100;
    }

   
}