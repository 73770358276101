.arbol__container{
   
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-Items: center;
        justify-Content: center;
        min-height: 100vh;
        background-image: url('../../Imgs/tree.png'); // URL de fondo
        background-size: cover; // Ajusta el tamaño del fondo
        background-position: center; // Centra el fondo
        color: #fff; // Cambia el color del texto para que sea legible
        padding: 20px;

        .arbol__links {
            
            width: 100%;
            max-Width: 350px;
         
          }
          
}}


.buttons-links{
        
        display: block;
        text-Align: center;
        padding: 15px;
        margin: 10px 0;
        text-Decoration: none;
        font-Weight: bold;
        color: #fff;
        background-Color: #25272b;
        border-Radius: 25px;
        box-Shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s;
     
     
}


.buttons-links:hover {
    background-Color: #000000;
  }

  .cuadrado{
    padding: 0%;
    margin: 0%;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: radial-gradient(at 53.42181513533113% 57.55304320462429%, hsla(132, 100%, 50%, 1) 0%, hsla(132, 100%, 50%, 0) 100%), radial-gradient(at 26.47942745894789% 55.97146334495058%, hsla(80.94117647058823, 100%, 50%, 1) 0%, hsla(80.94117647058823, 100%, 50%, 0) 100%), radial-gradient(at 55.9956333714718% 94.49218502940697%, hsla(132, 100%, 50%, 1) 0%, hsla(132, 100%, 50%, 0) 100%);
    
  }




  .cuadrado2{
    padding: 0%;
    margin: 0%;
    width: 13rem;
    text-align: left  ;
   font-size: large;
  }

  .flex-boton{
    display: flex;
    justify-content: center
    ;
    align-items: center;
    gap:2rem;
  }