.resume-preview {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #3a5f5f;
    color: #ffffff;
    padding: 20px;
  }
  
  .photo {
    width: 100px;
    height: 100px;
    background-color: #c4c4c4;
    border-radius: 50%;
  }
  
  .header-info h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .position {
    font-size: 18px;
    font-weight: 300;
    color: #d3e1e1;
  }
  
  .summary {
    padding: 20px;
    background-color: #ffffff;
    border-left: 4px solid #3a5f5f;
    margin: 20px 0;
  }
  
  .section {
    margin-top: 20px;
  }
  
  .section h3 {
    color: #3a5f5f;
    font-size: 20px;
    border-bottom: 2px solid #3a5f5f;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .section p,
  .section ul {
    margin: 5px 0;
  }
  
  .contact,
  .strengths,
  .languages {
    background-color: #e1e8e8;
    padding: 10px;
  }
  
  .experience, .education {
    background-color: #ffffff;
    padding: 10px;
    border-left: 4px solid #3a5f5f;
  }
  
  .experience ul {
    padding-left: 20px;
    list-style: disc;
  }
  